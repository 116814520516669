/* .empty-ui-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: #f7f9fc;

  font-family: "Roboto", sans-serif;
}

.empty-ui-animation {
  width: 550px;
  height: 550px;
  margin: 0 auto 20px;
}

.empty-ui-description {

  color: #777;
} */

/* .empty-ui-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: #f7f9fc;
  font-family: 'Roboto', sans-serif;
}

.empty-ui-animation {
  width: 550px;
  height: 550px;
  margin: 0 auto 20px;
}

.empty-ui-title {
  margin-bottom: 10px;
  color: #333;
}

.empty-ui-description {
  margin-bottom: 20px;
  color: #777;
}

.empty-ui-button {
  margin-top: 20px;
} */

.empty-ui-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: #f7f9fc;
  font-family: 'Roboto', sans-serif;
}

.empty-ui-animation {
  width: 100%;
  max-width: 550px;
  height: auto;
  /* margin: 0 auto 20px; */
}

.empty-ui-title {
  margin-bottom: 10px;
  color: #333;
}

.empty-ui-description {
  margin-bottom: 20px;
  color: #777;
}

.empty-ui-button {
  margin-top: 20px;
}

@media (max-width: 600px) {
  .empty-ui-animation {
    max-width: 300px;
  }

  .empty-ui-description {
    font-size: 16px;
  }

  .empty-ui-button {
    width: 100%;
  }
}
